<template>
  <div id="body">
    <div class="headerBox">
      <p style="text-align: left;padding-left: 20px;">广告列表</p>
    </div>
    <el-table :data="advData" v-loading="loading">
      <el-table-column prop="id" label="广告ID" align="center" width="80"></el-table-column>
      <el-table-column prop="advName" label="广告名称" align="center"> </el-table-column>
      <el-table-column prop="propagandaTitle" label="推广标题" align="center"></el-table-column>
      <el-table-column prop="stage" label="审核状态" align="center">
        <template slot-scope="scope">
          <span :style="scope.row.stage == '2' ? 'color:#67C23A' : scope.row.stage == '3' ? 'color:#F56C6C' : ''">
            {{scope.row.stage == "1" ? '待审核' : scope.row.stage == "2" ? '已通过' : scope.row.stage == "3" ? '已拒绝' : '无状态'}}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="range" label="投放范围 " align="center">
        <template slot-scope="scope">
          <span>{{scope.row.range == "1" ? '精准投放' : '范围投放'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="advType" label="广告模式" align="center" width="100">
        <template slot-scope="scope">
          <span>{{scope.row.advType == "1" ? '首屏广告' : scope.row.advType == "2" ?'信息流广告' : scope.row.advType == "3" ?'通知栏广告' : scope.row.advType == "4" ?'详情页广告': ''}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="everydayBudget" label="每日预算" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.everydayBudget == '0' ? '不限' : $util.func.setMoney(scope.row.everydayBudget)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="showNum" label="展示次数" align="center"></el-table-column>
      <el-table-column prop="totalCost" label="总花费" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.totalCost == '0' ? '无' : $util.func.setMoney(scope.row.totalCost)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="rate" label="竞价倍率" align="center" width="80"></el-table-column>
      <el-table-column prop="singlePrice" label="展示单价" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.singlePrice == '暂无'">无</span>
          <span v-else>{{$util.func.setMoney(scope.row.singlePrice)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="launchStartDay" label="开始投放时间" align="center" width="120"></el-table-column>
      <el-table-column prop="launchEndDay" label="结束投放时间" align="center" width="120">
        <template slot-scope="scope">
          <span>{{scope.row.launchEndDay == '0' ? '不限' : scope.row.launchEndDay}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" align="center" width="100"></el-table-column>
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <el-link type="primary" :underline="false" @click="detail(scope.row)">广告详情</el-link>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination @current-change="handleCurrentChange" :current-page="currpage" :page-size="pagesize" background
        layout="total, prev, pager, next, jumper" :total="CountNum" style="margin-top:15px">
      </el-pagination>
    </div>
    <el-dialog title="广告详情" :visible.sync="dialogVisible" width="40%" center top="5px">
      <div class="content">
        <p>
          <span>广告名称：</span>
          <span>{{data.advName}}</span>
        </p>
        <p>
          <span>推广标题：</span>
          <span>{{data.propagandaTitle}}</span>
        </p>
      </div>
      <div class="content">
        <p>
          <span>每日预算：</span>
          <span>{{data.everydayBudget == '0' ? '不限' : $util.func.setMoney(data.everydayBudget) + '元'}}</span>
        </p>
        <p>
          <span>用户报价：</span>
          <span>{{$util.func.setMoney(data.singlePrice) + '元'}}</span>
        </p>
      </div>
      <div class="content">
        <p>
          <span>投放日期：</span>
          <span>{{data.launchStartDay}} - {{data.launchEndDay}}</span>
        </p>
        <p>
          <span>投放时间：</span>
          <span>{{data.launchStartHour + '时'}} - {{data.launchEndHour + '时'}}</span>
        </p>
      </div>
      <div class="content">
        <p>
          <span>投放性别：</span>
          <span>{{data.gender == '0' ? '不限' : data.gender == '1' ? '男' : '女'}}</span>
        </p>
        <p>
          <span>广告模式：</span>
          <span>{{data.advType == '1' ? '首屏广告' : data.advType==2 ? '信息流广告' : data.advType==3 ? '通知栏广告' : data.advType==4 ? '详情页广告' : ''}}</span>
        </p>
      </div>
      <div class="content">
        <p>
          <span>投放地区：</span>
          <span v-if="data.province == '不限'">不限</span>
          <span v-else>{{data.province}}{{data.city}}{{data.area}}</span>
        </p>
        <p>
          <span>推送类型：</span>
          <span>{{data.exhibitionType == '1' ? '单图展示' : data.gender == '2' ? '大图展示' : '多图展示'}}</span>
        </p>
      </div>
      <div class="content">
        <p>
          <span>投放范围：</span>
          <span>{{data.range == '1' ? '精准投放' : '范围投放'}}</span>
        </p>
        <p>
          <span>创建时间：</span>
          <span>{{data.createTime}}</span>
        </p>
      </div>
      <div class="content-range">
        <p v-if="data.range == '1'">
          <span>投放区域(学校)：</span>
          <span>{{data.school == '0' ? '不限' : data.school.replace(/[/]/g,",")}}</span>
        </p>
        <p v-if="data.range == '2'">
          <span>投放区域(年级)：</span>
          <span>{{data.classType == '0' ? '不限' : data.classType.replace(/[/]/g,",")}}</span>
        </p>
      </div>
      <div class="content">
        <p>
          <span>展示图片：</span>
        </p>
      </div>
      <div class="content-img" v-if="data.exhibitionType == '3'">
        <img v-for="item in imgShow" :key="item" :src="item" alt="图片" width="160px" height="90px">
      </div>
      <div class="content-img" v-else>
        <img v-for="item in imgShow" :key="item" :src="item" alt="图片" width="135px" height="240px">
      </div>
      <div class="content-detail">
        <p>
          <span>广告详情：</span>
        <div v-html="data.advDetail"></div>
        </p>
      </div>
      <span slot="footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="danger" @click="check(3)">拒绝</el-button>
        <el-button type="success" @click="check(2)">通过</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    advList,
    advDetail,
    advExamine
  } from '@/api/adminApi.js'
  export default {
    data() {
      return {
        advData: [], // 广告主列表数据
        pagesize: 10, // 每页显示条目个数
        currpage: 1, // 初始页
        CountNum: 0, // 总条数
        loading: false, //加载框
        imgShow: [], //显示的展示图片
        dialogVisible: false, //广告详情弹窗
        data: {}, //广告详情数据
      }
    },
    mounted() {
      this.advList(1) // 获取广告主列表
    },
    methods: {
      advList(currpage) { // 广告主列表
        this.loading = true
        const data = {}
        data["page"] = currpage //	当前页数
        data["limit"] = this.pagesize // 每页条数
        data["stage"] = '100' //审核状态 ( 1待审核2已通过3已拒绝 100全部 )
        advList(data).then(res => {
          this.CountNum = parseInt(res.data.CountNum) // 总条数
          this.advData = res.data.List // 设备列表
          this.currpage = currpage //分页显示当前页
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      },
      detail(row) { // 点击广告详情
        let data = {
          advId: row.id
        }
        advDetail(data).then(res => {
          this.data = res.data //广告详情数据
          this.imgShow = JSON.parse(res.data.advPic) //将展示图片转换成数组对象
          this.dialogVisible = true //打开弹窗
        }).catch(err => {
          console.log(err)
        })
      },
      check(num) {
        let data = {
          advId: this.data.id, //广告id
          stage: num //审核状态 ( 2通过 3拒绝 )
        }

        // if(this.advData.length == 1 &&  this.currpage > 1){
        //     this.currpage = this.currpage -1;
        // }else if(this.advData.length == 1 && this.currpage == 1){
        //     this.currpage = 1;
        // }

        advExamine(data).then(res => {
          if (res.status.code == 1) {
            if (num == 2) {
              this.$message.success("广告已通过")
            } else {
              this.$message.error("广告已拒绝")
            }
            this.dialogVisible = false // 关闭弹窗
            this.advList(this.currpage) // 获取广告主列表
          } else {
            this.$message.error(res.status.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      },
      handleCurrentChange(val) { // 分页
        this.advList(val)
      }
    }
  }
</script>
<style lang="scss" scoped>
  .content {
    display: flex;

    p {
      display: flex;
      width: 50%;

      span:nth-child(1) {
        width: 32%;
        text-align: right;
      }

      span:nth-child(2) {
        width: 70%;
        text-align: left;
      }
    }
  }

  .content-img {
    display: flex;
    justify-content: center;
    gap: 1%;
  }

  .content-detail {
    span {
      margin-left: 6%;
    }

    div {
      width: 50%;
      margin: 0 auto;
    }
  }

  .content-range {
    display: flex;

    p {
      display: flex;
      width: 100%;

      span:nth-child(1) {
        width: 16%;
        text-align: right;
      }

      span:nth-child(2) {
        width: 84%;
        text-align: left;
      }
    }
  }
</style>
